import React from 'react';
import queryString from 'query-string';

const User = () => {


    function GetLocationParams()
{
  let url = window.location.search
  let params = queryString.parse(url);
  return params
}


    return(
        
        <div style={{position: 'absolute',
  width: '100%',
  height: '100%',
  border:"none"}}>
            <iframe src={"https://fortrinnuser.web.app/"}allowFullscreen="yes" style={{border:"none"}} height="100%" width="100%" title="User" ></iframe>
            </div>
    )
}


export default User;
