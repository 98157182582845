import React from 'react';
import queryString from 'query-string';

const Stream = () => {
    const projectid = GetLocationParams().id
    const medvirkning = GetLocationParams().medv


    function GetLocationParams()
{
  let url = window.location.search
  let params = queryString.parse(url);
  return params
}


    return(
        
        <div style={{position: 'absolute',
  width: '100%',
  height: '100%',
  border:"none"}}>
      {/* <iframe src={"https://match.blaar.no/?id=7OqRcqMGCUfT3w0GNbJt"} allowvr="yes" allowxr="yes" allow="xr" allow="xr-spatial-tracking" allowFullscreen="yes" style={{border:"none"}} height="100%" width="100%" title="Streaming" ></iframe> */}
      
            <iframe src={"https://cloud.blaar.no/?id="+projectid} allowvr="yes" allowxr="yes" allow="xr" allow="xr-spatial-tracking" allowFullscreen="yes" style={{border:"none"}} height="100%" width="100%" title="Streaming" ></iframe>
            </div>
    )
}


export default Stream;
