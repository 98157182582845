import React from 'react';
import queryString from 'query-string';

var popup
const Stream2 = () => {
    const projectid = GetLocationParams().id
    const medvirkning = GetLocationParams().medv


    function GetLocationParams()
{
  let url = window.location.search
  let params = queryString.parse(url);
  return params
}

function OpenStream(){
  //popup = window.open("https://cloud.fortrinnstudio.no:3005/?id="+projectid)
  //popup = window.open("https://localhost:3000/streamtest/?id="+projectid)
  console.log('open window')
  popup = window.open("https://webmodell.no/streamtest/?id="+"k78qiES4luwj3LpbGm68", "", "width=1920,height=1080"  )
}

function SendMessage(){
  popup.postMessage('fps','*');
  }


    return(
        
        <div style={{position: 'absolute',
  width: '100%',
  height: '100%',
  border:"none"}}>
      {/* <iframe src={"https://match.blaar.no/?id=7OqRcqMGCUfT3w0GNbJt"} allowvr="yes" allowxr="yes" allow="xr" allow="xr-spatial-tracking" allowFullscreen="yes" style={{border:"none"}} height="100%" width="100%" title="Streaming" ></iframe> */}
      
      <button type="button" onClick={OpenStream}>Open Stream</button>
      <button type="button" onClick={SendMessage}>Send info</button>
            </div>
    )
}


export default Stream2;
