import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import Testimonial from '../components/sections/Testimonial';
import Clients from '../components/sections/Clients';
import Team from '../components/sections/Team';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import GenericSection from '../components/sections/GenericSection';
import Cta from '../components/sections/Cta';
// import some required elements
import Image from '../components/elements/Image';
import Input from '../components/elements/Input';
import ButtonGroup from '../components/elements/ButtonGroup';
import Button from '../components/elements/Button';
import Modal from '../components/elements/Modal';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';



import * as firebase from 'firebase'


  //const db = firebase.firestore();

  /*
  const AddSubscription = async (tempmail) => 
  {
    const savelog = await db.collection('subscription').add({
    email: tempmail
  });
  }
*/
class Unsubscribe extends React.Component {

    state = {
        db : firebase.firestore(),
        demoModalActive: false,
        email: ""
      }

    RemoveSubscription = async (event,tempmail) => 
    {
      event.preventDefault();
      const savelog = await this.state.db.collection('subscription').doc(tempmail).delete().catch(error => {
        console.log(error)
    }).then(
      this.props.history.push("/unsubscribed")
      //this.context.history.push('/path')
    );
  }


    // {
    //   const savelog = await this.state.db.collection('subscription').doc(tempmail).set({
    //   active: false
    // }).catch(error => {
    //     console.log(error)
    // });
    // }


  

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {

    const genericSection01Header = {
      title: ''
    }    

    return (
      <React.Fragment>
        <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data={genericSection01Header} className="center-content" />
           
            {/* <div className="center-content">
              <Button
                color="secondary"
                aria-controls="demo-modal"
                onClick={this.openModal}>Subscribe</Button>
            </div> */}
            {/* <Modal
              id="demo-modal"
              show={this.state.demoModalActive}
              handleClose={this.closeModal}
            > */}
              <div className="center-content">
                <h3 className="mt-16 mb-8">Meld deg av nyhetsbrev</h3>
                <p className="text-sm">
                  skriv inn mailen din for å melde deg av
                </p>
              </div>
{/*               
              <form style={modalFormStyle}>
                <div className="mb-12">
                  <Input
                    type="email"
                    label=""
                    placeholder="Your email"
                    labelHidden
                    onChange={e => this.state.email = "hest"}/>
                </div>
                
              </form> */}
               <form>
                <div className="mb-12">
                  <Input
                    type="email"
                    label=""
                    placeholder="Your email"
                    labelHidden
                    onChange={e => this.state.email = e.target.value}/>
                </div>
              <Button color="primary" onClick={e => this.RemoveSubscription(e,this.state.email)} wide>Avmeld</Button>
              </form>
              <div className="center-content mt-24">
                <a
                  className="text-xs"
                  aria-label="close"
                  href="#0"
                  onClick={this.closeModal}
                >Forbli påmeldt</a>
              </div>
            {/* </Modal> */}
          </div >
        </GenericSection>           
      </React.Fragment>
    );
  }
}

// inline style
const formStyle = {
  maxWidth: '420px',
  margin: '0 auto'
}

const modalFormStyle = {
  maxWidth: '320px',
  margin: '0 auto'
}

export default Unsubscribe;