import React from 'react';
import queryString from 'query-string';

const Webviewer = () => {
    const projectid = GetLocationParams().id
    const medvirkning = GetLocationParams().medv


    function GetLocationParams()
{
  let url = window.location.search
  let params = queryString.parse(url);
  return params
}


    return(
        
        <div style={{position: 'absolute',
  width: '100%',
  height: '100%',
  border:"none"}}>
            <iframe src={"https://webviewer.web.app/?id="+projectid+"&medv="+medvirkning} allowvr="yes" allowxr="yes" allow="xr" allow="xr-spatial-tracking" allowFullscreen="yes" style={{border:"none"}} height="100%" width="100%" title="Webviewer" ></iframe>
            </div>
    )
}


export default Webviewer;
