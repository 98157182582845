import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutAlternative from './layouts/LayoutAlternative';
import LayoutSignin from './layouts/LayoutSignin';

// Views 
import Home from './views/Home';
import Secondary from './views/Secondary';
import Login from './views/Login';
import Signup from './views/Signup';
import Webviewer from './views/Webviewer.js';
import Unsubscribe from './views/Unsubscribe.js';
import Unsubscribed from './views/Unsubscribed.js';

import Stream from './views/Stream.js';
import Stream2 from './views/Stream2.js';

import User from './views/User.js';

import * as firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyCvZOv1rn5bBJQ2bHn58Qnd9GCE3vli9Ls",
  authDomain: "fortrinn-47822.firebaseapp.com",
  projectId: "fortrinn-47822"
};
firebase.initializeApp(firebaseConfig);


class App extends React.Component {


  componentDidMount() {
    document.body.classList.add('is-loaded')
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute exact path="/webviewer" component={Webviewer}/>
            <AppRoute exact path="/stream" component={Stream}/>
            <AppRoute exact path="/stream2" component={Stream2}/>
            <AppRoute exact path="/unsubscribe" component={Unsubscribe}/>
            <AppRoute exact path="/unsubscribed" component={Unsubscribed}/>
            <AppRoute exact path="/secondary" component={Secondary} layout={LayoutAlternative} />
            <AppRoute exact path="/login" component={Login} layout={LayoutSignin} />
            <AppRoute exact path="/signup" component={Signup} layout={LayoutSignin} />
            <AppRoute exact path="/user" component={User}/>
          </Switch>
        )} />
    );
  }
}

export default withRouter(props => <App {...props} />);